
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-ideabox",
    components: { Field },
    data: function () {
        return {
            category: {
                id: null,
                label: "",
                order: 1,
            },
            categories: [],
        };
    },
    methods: {
        initAdd() {
            this.category = {
                id: null,
                label: "",
                order: 1,
            };
        },
        initEdit(category) {
            this.category = {
                id: category.pk,
                label: category.label,
                order: category.order,
            };
        },
        deleteCategory(category) {
            ideaBoxApi.deleteCategory(category.pk).then(() => {
                location.reload();
            });
        },
        addCategory() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.category.id) {
                ideaBoxApi.updateCategory(this.category.id, this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            } else {
                ideaBoxApi.addCategory(this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            }
        },
        getCategories() {
            ideaBoxApi.getCategories().then((response) => {
                this.categories = response.data["categories"];
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.ideabox.title"), []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.ideabox.title"), []);
        },
    },
    created() {
        this.getCategories();
    },
});
